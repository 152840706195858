import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Layout } from '../../Layout/Layout'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const Successful = () => {
  const { resetLookup, resetSignup } = useLoyaltyContext()
  const { push } = useHistory()

  return (
    <Layout>
      <div className='font-bold type-headline-5 flex justify-center'>
        We’ve sent you a message with more info!
      </div>
      <div className='flex justify-center'>
        Check your inbox for more information.
      </div>
      <Button className='md:w-1/3' onClick={() => resetLookup()} size='auto'>
        Look up an account
      </Button>

      <Button
        className='w-1/3 text-link'
        onClick={() => {
          resetSignup()
          push('rewardsSignup')
        }}
        variant='link'
      >
        Sign up
      </Button>
    </Layout>
  )
}
