import { LoyaltyProvider } from '../context/LoyaltyContext'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { SignUp } from '../SignUp/SignUp'
import { QueryClientProvider } from '../context/QueryClientProvider'
import { Lookup } from '../Lookup/Lookup'

export function App() {
  return (
    <QueryClientProvider>
      <LoyaltyProvider>
        <BrowserRouter basename='/'>
          <Switch>
            <Route exact path='*/:shortUrl/rewardsSignup'>
              <SignUp />
            </Route>
            <Route path='*/:shortUrl/rewardsLookup'>
              <Lookup />
            </Route>
          </Switch>
        </BrowserRouter>
      </LoyaltyProvider>
    </QueryClientProvider>
  )
}
