import React from 'react'
import { Layout } from '../Layout/Layout'

export const InactiveProgram = () => {
  return (
    <Layout>
      <div className='font-bold type-headline-5'>
        This loyalty program is currently inactive
      </div>
      <div>Please contact us with any questions</div>
    </Layout>
  )
}
