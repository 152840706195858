import { CardContainer } from '@toasttab/buffet-pui-card'
import React from 'react'

export const MiniCardContainer = ({ children }: any) => {
  return (
    <CardContainer className='!bg-info-0 w-1/3 text-link flex justify-center'>
      {children}
    </CardContainer>
  )
}
