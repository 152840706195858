export type LoyaltyConfig = {
  isRewardsEnabled: boolean
  programDescription: string
  programName: string
  restaurantName: string
  signUpMethod: SignupMethod
  ooAvailable: boolean
  takeoutAvailable: boolean
  giftCardsAvailable: boolean
  facebookUrl: string
  twitterUrl: string
  restaurantWebsite: string
  restaurantPhoneNumber: string
  restaurantLogoPath: string
  restaurantBgImagePath: string
  restaurantBannerImagePath: string
  instagramHandle: string
  smsMarketingAccountEnabled: boolean
  restaurantCountryCode: CountryCode
}

export type CountryCode = 'US' | 'CA' | 'IE' | 'GB'

export enum SignupMethod {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER'
}

export type RequestParams = {
  email: string
  phone: string
  token: string
}

export interface SignUpParams extends RequestParams {
  subscribeToSmsMarketing?: boolean
}
