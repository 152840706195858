import React, { useState, useEffect } from 'react'
import { isValidEmail } from '@toasttab/email-validation'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { SignupMethod } from '../../types/types'
import { EmailInput } from '../../FormComponents/EmailInput'
import { PhoneInput } from '../../FormComponents/PhoneInput'
import { ReCaptcha } from '../../FormComponents/ReCaptcha'
import { useHistory } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SmsSubscriptionToggle } from '../../FormComponents/SmsSubscriptionToggle'
import { trackEvent } from '@local/tracking'
import { isValidPhoneNumber } from 'libphonenumber-js'

export const SignupForm = () => {
  const { config, signUp, isSignUpLoading, resetLookup } = useLoyaltyContext()

  const { push } = useHistory()

  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [captchaToken, setCaptchaToken] = useState()
  const [inputValue, setInputValue] = useState('')
  const [enableSmsSubscription, setEnableSmsSubscription] = useState(false)

  const isEmailSignUp = config?.signUpMethod === SignupMethod.EMAIL
  const isSmsEnabled = !!config?.smsMarketingAccountEnabled

  const handleSubmit = () => {
    if (isEmailSignUp) {
      signUp({ email: inputValue, token: captchaToken })
    } else {
      signUp({
        phone: inputValue,
        token: captchaToken,
        subscribeToSmsMarketing: isSmsEnabled && enableSmsSubscription
      })
    }
  }

  useEffect(() => {
    if (config?.signUpMethod)
      trackEvent({
        name: 'Signup Form',
        properties: {
          signupMethod: config.signUpMethod
        }
      })
  }, [config?.signUpMethod])

  const isValid = isEmailSignUp
    ? isValidEmail(inputValue)
    : isValidPhoneNumber(inputValue, config?.restaurantCountryCode || 'US')

  return (
    <div className='max-w-md p-4 space-y-4 rounded-lg'>
      {isEmailSignUp ? (
        <EmailInput
          emailInput={inputValue}
          setEmailInput={setInputValue}
          required={true}
        />
      ) : (
        <PhoneInput
          phoneInput={inputValue}
          setPhoneInput={setInputValue}
          required={true}
        />
      )}
      {!isEmailSignUp && isSmsEnabled && (
        <SmsSubscriptionToggle
          isActive={enableSmsSubscription}
          onChange={setEnableSmsSubscription}
        />
      )}
      <div className='flex flex-col justify-center items-center'>
        <ReCaptcha
          setCaptchaVerified={setCaptchaVerified}
          setCaptchaToken={setCaptchaToken}
        />
        <Button
          disabled={!isValid || !captchaVerified || isSignUpLoading}
          onClick={handleSubmit}
          className='mt-4 w-full'
        >
          Sign up
        </Button>
      </div>
      <Button
        className='mt-4 w-full text-link flex justify-center'
        variant='link'
        onClick={() => {
          resetLookup()
          push('rewardsLookup')
        }}
      >
        Sign in to an existing account
      </Button>
    </div>
  )
}
