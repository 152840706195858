import React from 'react'
import { POWERED_BY_TOAST_URL } from '../../consts/consts'
import { Image } from '@toasttab/buffet-pui-image'

export const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className='py-8'>
      <a
        id='lw-click-footer-toast'
        href='http://pos.toasttab.com/?source=footer'
        aria-label='powered by toast'
        className='hover:no-underline group'
      >
        <div className='flex justify-center'>
          <Image
            src={POWERED_BY_TOAST_URL}
            alt='Powered by Toast'
            onResize={undefined}
            onResizeCapture={undefined}
          />
        </div>
        <p className='type-subhead text-center text-gray-75 group-hover:text-link pt-2'>
          © {year} Toast, Inc
        </p>
      </a>
    </div>
  )
}
