import React from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const ContactInfoFooter = () => {
  const { config } = useLoyaltyContext()

  const restaurantWebsite = config?.restaurantWebsite
  const restaurantPhoneNumber = config?.restaurantPhoneNumber
  return (
    <div className='bg-info-0 p-5 rounded-b'>
      <div className='flex flex-col items-center justify-center'>
        {restaurantWebsite && (
          <a
            aria-label='link to restaurant website'
            className='inline-link'
            href={`https://${restaurantWebsite}`}
          >
            {restaurantWebsite}
          </a>
        )}
        {restaurantPhoneNumber && (
          <a
            aria-label='link to restaurant phone number'
            className='inline-link'
            href={`tel:${restaurantPhoneNumber}`}
          >
            {restaurantPhoneNumber}
          </a>
        )}
      </div>
    </div>
  )
}
