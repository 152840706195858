import { GOOGLE_SITE_KEY_LOCAL } from '../config/config-local'
import { GOOGLE_SITE_KEY_PROD } from '../config/config-production'

import {
  ToastEnvironment,
  getCurrentEnvironment
} from '@toasttab/current-environment'

export const GOOGLE_SITE_KEY =
  getCurrentEnvironment() === ToastEnvironment.PROD
    ? GOOGLE_SITE_KEY_PROD
    : GOOGLE_SITE_KEY_LOCAL

export const POWERED_BY_TOAST_URL =
  'https://cdn.toasttab.com/static/bc2dc36d5d944af651b55149ec229f34504fdcc3/images/guest/ddi/powered-by-toast.png'
