import { AxiosError } from 'axios'
import { TrackingEvent } from '../types'
import { trackEvent } from '../metrics'

export const trackError = (event: TrackingEvent, error: unknown) => {
  const { errorCode, errorMessage } = getErrorCodeAndMessage(error)
  const eventWithError: TrackingEvent = {
    ...event,
    properties: {
      ...event.properties,
      errorCode,
      errorMessage
    }
  }
  trackEvent(eventWithError)
}

// Note: we aren't quite sure what shape the error will come
// back, as not all our services conform to the same error response
// so we're going to parse as best we can with what gets returned
export const getErrorCodeAndMessage = (error: unknown) => {
  if (typeof error === 'string' || error instanceof String) {
    return {
      errorCode: 'unknown',
      errorMessage: `${error}`
    }
  }
  const e = error as AxiosError
  const errorCode =
    e?.response?.status ||
    e?.response?.data?.code ||
    e?.response?.data?.errorCode ||
    e?.code ||
    'unknown'
  const errorMessage =
    e?.response?.statusText ||
    e?.response?.data?.message ||
    e?.response?.data?.errorMessage ||
    e?.message ||
    'unknown'
  return {
    errorCode: `${errorCode}`,
    errorMessage: `${errorMessage}`
  }
}
