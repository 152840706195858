import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useApi } from '../services/useApi'
import {
  LoyaltyConfig,
  RequestParams,
  SignUpParams,
  SignupMethod
} from '../types/types'
import { trackError, trackEvent } from '@local/tracking'

interface LoyaltyContextType {
  configLoading: boolean
  config: LoyaltyConfig | undefined
  configError: any
  isSignUpLoading: boolean
  isSignUpSuccess: boolean
  isSignUpError: boolean
  signUp: any
  isLookupSuccess: boolean
  isLookupLoading: boolean
  isLookupError: boolean
  lookUp: any
  resetLookup: () => void
  resetSignup: () => void
}

const LoyaltyContext = React.createContext<LoyaltyContextType>({
  configLoading: false,
  config: undefined,
  configError: undefined,
  isSignUpLoading: false,
  isSignUpSuccess: false,
  isSignUpError: false,
  signUp: undefined,
  isLookupSuccess: false,
  isLookupLoading: false,
  isLookupError: false,
  lookUp: undefined,
  resetLookup: () => {},
  resetSignup: () => {}
})

export const useLoyaltyContext = () => {
  return React.useContext(LoyaltyContext)
}

export const LoyaltyProvider: React.FC = ({ children }) => {
  const { getLoyaltyConfig, submitSignUpRequest, submitLookupRequest } =
    useApi()

  const {
    isLoading: configLoading,
    data: loyaltyData,
    error: configError
  } = useQuery('getLoyaltyConfig', () => getLoyaltyConfig(), {
    onSuccess: () => {
      trackEvent({ name: 'Get loyalty config success' })
    },
    onError: (error) => {
      trackError({ name: 'Get loyalty config error' }, error)
    }
  })

  const {
    mutate: signUp,
    isLoading: isSignUpLoading,
    isSuccess: isSignUpSuccess,
    isError: isSignUpError,
    reset: resetSignup
  } = useMutation(
    ({ email, phone, token, subscribeToSmsMarketing }: SignUpParams) =>
      submitSignUpRequest({ email, phone, token, subscribeToSmsMarketing }),
    {
      onSuccess: (_, { email, subscribeToSmsMarketing }) => {
        const signupMethod = !!email
          ? SignupMethod.EMAIL
          : SignupMethod.PHONE_NUMBER
        const subscribedToSms = !!subscribeToSmsMarketing ? 'True' : 'False'
        trackEvent({
          name: 'Sign up success',
          properties: {
            signupMethod,
            subscribedToSms
          }
        })
      },
      onError: (error) => {
        trackError({ name: 'Sign up error' }, error)
      }
    }
  )

  const {
    mutate: lookUp,
    isLoading: isLookupLoading,
    isSuccess: isLookupSuccess,
    isError: isLookupError,
    reset: resetLookup
  } = useMutation(
    ({ email, phone, token }: RequestParams) =>
      submitLookupRequest({ email, phone, token }),
    {
      onSuccess: () => {
        trackEvent({ name: 'Look up success' })
      },
      onError: (error) => {
        trackError({ name: 'Look up error' }, error)
      }
    }
  )

  const contextValue: LoyaltyContextType = {
    config: loyaltyData?.data,
    configLoading,
    configError,
    isSignUpSuccess,
    isSignUpLoading,
    isSignUpError,
    signUp,
    isLookupSuccess,
    isLookupLoading,
    isLookupError,
    lookUp,
    resetLookup,
    resetSignup
  }

  return (
    <LoyaltyContext.Provider value={contextValue}>
      {children}
    </LoyaltyContext.Provider>
  )
}
