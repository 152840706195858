import { useMockContext } from '@local/mock-provider'
import {
  getLoyaltyConfig,
  submitSignUpRequest,
  submitLookupRequest
} from './api'

export const useApi = () => {
  const { mockGetLoyaltyConfig } = useMockContext()
  return {
    getLoyaltyConfig: mockGetLoyaltyConfig || getLoyaltyConfig,
    submitSignUpRequest: submitSignUpRequest,
    submitLookupRequest: submitLookupRequest
  }
}
