import axios from 'axios'
import { RequestParams, SignUpParams } from '../types/types'

const LOYALTY_CONFIG_URL = 'loyalty/sign-up-data'
const LOYALTY_SIGNUP_URL = `loyalty/sendRewardsSignUpRequest`
const LOYALTY_LOOKUP_URL = `loyalty/sendRewardsLookUpRequest`

export const getLoyaltyConfig = () => axios.get(LOYALTY_CONFIG_URL)

export const submitSignUpRequest = ({
  email = '',
  phone = '',
  token = '',
  subscribeToSmsMarketing = false
}: SignUpParams) =>
  axios.post(LOYALTY_SIGNUP_URL, {
    email,
    phone,
    token,
    subscribeToSmsMarketing
  })

export const submitLookupRequest = ({
  email = '',
  phone = '',
  token = ''
}: RequestParams) => axios.post(LOYALTY_LOOKUP_URL, { email, phone, token })
