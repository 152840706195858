import React from 'react'
import { Footer } from './Footers/Footer'
import { useLoyaltyContext } from '../context/LoyaltyContext'
import { ContactInfoFooter } from './Footers/ContactInfoFooter'
import { CardContainer, paddingStyles } from '@toasttab/buffet-pui-card'
import { Banner } from './Header/Banner'
import cx from 'classnames'
import { RestaurantHeader } from './Header/RestaurantHeader'

export const Layout = ({ children }: any) => {
  const { config } = useLoyaltyContext()

  return (
    <div className='h-screen flex flex-col'>
      <div
        className='flex-1 bg-cover bg-opacity-30 bg-blend-lighten bg-white'
        style={{ backgroundImage: `url(${config?.restaurantBgImagePath}` }}
      >
        <Banner />
        <CardContainer
          className='-mt-48 z-0 relative md:container md:mx-auto'
          noPadding
        >
          <div
            className={cx(
              'space-y-4 flex flex-col items-center',
              paddingStyles
            )}
          >
            <RestaurantHeader />
            {children}
          </div>
          <ContactInfoFooter />
        </CardContainer>
      </div>
      <Footer />
    </div>
  )
}
