import React from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const Banner = () => {
  const { config } = useLoyaltyContext()

  return (
    <div
      className='bg-cover'
      style={{
        backgroundImage: `url(${config?.restaurantBannerImagePath}`,
        height: '300px'
      }}
      data-testid='banner-image'
    />
  )
}
