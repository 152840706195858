import React, { useEffect } from 'react'
import { GOOGLE_SITE_KEY } from '../consts/consts'

export const ReCaptcha = ({ setCaptchaVerified, setCaptchaToken }: any) => {
  function onChangeRecaptcha(token: string | null) {
    setCaptchaToken(token)
    setCaptchaVerified(token)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/enterprise.js'
    script.async = true
    script.defer = true
    ;(window as any).onChangeRecaptcha = onChangeRecaptcha

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <form action='?' method='POST'>
      <div
        data-testid='recaptcha'
        className='g-recaptcha'
        data-sitekey={GOOGLE_SITE_KEY}
        data-callback='onChangeRecaptcha'
      ></div>
    </form>
  )
}
