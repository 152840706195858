import { useLoyaltyContext } from '../context/LoyaltyContext'
import React from 'react'
import { InactiveProgram } from '../InactiveProgram/InactiveProgram'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { Loading } from '../Loading/Loading'
import { LookupForm } from './LookupForm/LookupForm'
import { Successful } from './Successful/Successful'
import { Layout } from '../Layout/Layout'

export const Lookup = () => {
  const { configLoading, config, configError, isLookupSuccess, isLookupError } =
    useLoyaltyContext()

  if (configError || isLookupError) return <ErrorPage500 />
  if (configLoading) return <Loading />
  if (!config?.isRewardsEnabled) return <InactiveProgram />
  if (isLookupSuccess) return <Successful />

  return (
    <Layout>
      <h1 className='type-large text-default text-center font-bold'>
        Look up your {config?.programName} rewards account
      </h1>
      <div>Use your email or phone number to look up your account</div>
      <LookupForm />
    </Layout>
  )
}
