import React from 'react'
import { MockContextType } from './types'

export interface MockProviderProps extends MockContextType {
  children: React.ReactNode
}

const MockContext = React.createContext<MockContextType>({})

export const MockProvider = ({ children, ...props }: MockProviderProps) => {
  return <MockContext.Provider value={props}>{children}</MockContext.Provider>
}

export const useMockContext = () => {
  return React.useContext(MockContext)
}
