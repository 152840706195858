import React from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { useParams } from 'react-router-dom'
import { FacebookIcon, InstagramIcon } from '@toasttab/buffet-pui-icons'
import { Layout } from '../../Layout/Layout'
import { MiniCardContainer } from './MiniCardContainer'
import cx from 'classnames'

export const Successful = () => {
  const { config } = useLoyaltyContext()
  const { shortUrl }: { shortUrl: string } = useParams()

  const instagramHandle = config?.instagramHandle
  const facebookUrl = config?.facebookUrl

  return (
    <Layout>
      <h1 className='font-bold type-headline-5'>
        Welcome to {config?.programName}!
      </h1>
      <div>Check your inbox for more information.</div>
      {config?.ooAvailable && (
        <MiniCardContainer>
          <a
            href={`/${shortUrl}/v3/?utmSource=loyalty-signup&mode=fulfillment`}
          >
            Check out our online menu
          </a>
        </MiniCardContainer>
      )}
      {config?.takeoutAvailable && config?.restaurantCountryCode === 'US' && (
        <MiniCardContainer>
          <a href='https://toasttakeout.page.link/loyalty'>
            Download the Local by Toast app
          </a>
        </MiniCardContainer>
      )}
      {config?.giftCardsAvailable && (
        <MiniCardContainer>
          <a href={`/${shortUrl}/giftcards?utm_campaign=loyalty-signup`}>
            Buy a Gift Card
          </a>
        </MiniCardContainer>
      )}
      {(facebookUrl || instagramHandle) && (
        <MiniCardContainer>
          <div className='flex flex-col'>
            <div>Follow us on social media</div>
            <div
              className={cx('flex justify-center', {
                'space-x-3': facebookUrl && instagramHandle
              })}
            >
              {facebookUrl && (
                <a
                  href={facebookUrl}
                  aria-label='facebook-link'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FacebookIcon />
                </a>
              )}
              {instagramHandle && (
                <a
                  href={instagramHandle}
                  aria-label='instagram-link'
                  target='_blank'
                  rel='noreferrer'
                >
                  <InstagramIcon />
                </a>
              )}
            </div>
          </div>
        </MiniCardContainer>
      )}
    </Layout>
  )
}
