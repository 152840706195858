import React from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const RestaurantHeader = () => {
  const { config } = useLoyaltyContext()

  return (
    <div>
      {config?.restaurantLogoPath && (
        <div>
          <img
            className='border-white rounded-lg shadow'
            src={config?.restaurantLogoPath}
            alt={`${config?.restaurantName} logo`}
            style={{
              marginTop: '-95px'
            }}
          />
        </div>
      )}
    </div>
  )
}
